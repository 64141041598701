import './App.css';
import React, { Component } from "react";
import ReactGA from "react-ga";
import Home from "./components/Home";

import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";


class App extends Component {
  constructor(props) {
    super(props);

    ReactGA.initialize("UA-208511787-1");
    ReactGA.pageview(window.location.pathname);
  }

  render() {
    return (
      <BrowserRouter>
      
        <main>
          <Switch>
            <Route path="/" exact component={Home} />
            <Redirect from="*" to="/" />
          </Switch>

        </main>
      </BrowserRouter>
    );
  }
}

export default App;