import React, { Component } from "react";
import styled from 'styled-components';

const HomeStyles = styled.div`
    // background-color: var(--color-section);
    font-family: qingke, sans-serif;
    font-weight: 900;
    letter-spacing: 16px;
    font-size: 2em;
    height: 100%;
    width: 100%;
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;
   
`;


class Home extends Component {
    render() {
        return (
            <main>
                <HomeStyles>
                    COMING SOON ...
                </HomeStyles>

            </main>
        )
    }
}
  
export default Home;